const BuyingIcon = ({ size = 22, color = '#01172F' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 38"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.02857 8.94287H26.9714C29.7486 8.94287 32 11.1942 32 13.9714V32.2572C32 35.0344 29.7486 37.2857 26.9714 37.2857H5.02857C2.25137 37.2857 0 35.0344 0 32.2572V13.9714C0 11.1942 2.25137 8.94287 5.02857 8.94287ZM26.9716 11.6857H5.02875C3.76639 11.6857 2.74304 12.7091 2.74304 13.9714V32.2572C2.74304 33.5195 3.76639 34.5429 5.02875 34.5429H26.9716C28.234 34.5429 29.2573 33.5195 29.2573 32.2572V13.9714C29.2573 12.7091 28.234 11.6857 26.9716 11.6857Z"
      fill={color}
    />
    <path
      d="M22.3999 15.3436C23.0942 15.3436 23.668 15.8595 23.7589 16.5289L23.7714 16.715V18.5436C23.7714 22.8356 20.292 26.315 15.9999 26.315C11.8341 26.315 8.43389 23.0373 8.23748 18.9201L8.22852 18.5436V16.715C8.22852 15.9576 8.84253 15.3436 9.59994 15.3436C10.2942 15.3436 10.868 15.8595 10.9589 16.5289L10.9714 16.715V18.5436C10.9714 21.3208 13.2227 23.5721 15.9999 23.5721C18.6743 23.5721 20.861 21.4845 21.0193 18.8499L21.0285 18.5436V16.715C21.0285 15.9576 21.6425 15.3436 22.3999 15.3436Z"
      fill={color}
    />
    <path
      d="M15.9999 0.714294C20.1658 0.714294 23.566 3.99202 23.7624 8.10919L23.7714 8.48572V10.3143C23.7714 11.0717 23.1574 11.6857 22.3999 11.6857C21.7056 11.6857 21.1318 11.1698 21.041 10.5004L21.0285 10.3143V8.48572C21.0285 5.70852 18.7771 3.45715 15.9999 3.45715C13.3256 3.45715 11.1389 5.54484 10.9806 8.1794L10.9714 8.48572V10.3143C10.9714 11.0717 10.3574 11.6857 9.59994 11.6857C8.90564 11.6857 8.33185 11.1698 8.24104 10.5004L8.22852 10.3143V8.48572C8.22852 4.19368 11.7079 0.714294 15.9999 0.714294Z"
      fill={color}
    />
  </svg>
);

export default BuyingIcon;
