import { Icon } from '@iconify/react';
import { type IconifyIcon } from '@iconify/types';
import { Box, type BoxProps } from '@mui/material';

export default function Iconify({
  icon,
  ...other
}: {
  icon: IconifyIcon | string;
} & BoxProps) {
  return <Box component={Icon} icon={icon} {...other} />;
}
