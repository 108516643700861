// material
import {
  type CustomTheme,
  experimentalStyled as styled,
} from '@mui/material/styles';

export const FilterListItemStyles = (theme: CustomTheme) => ({
  textTransform: 'capitalize',
  width: 'auto',
  color: theme.palette.text.secondary,
  borderRadius: `${theme.shape.borderRadius}px`,
  marginBottom: theme.spacing(0.4),
  cursor: 'pointer',
  height: 38,
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  ...theme.typography.body1,
  '&:hover': {
    backgroundColor: '#f0f5fd',
  },
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

export const SubIconStyle = styled('span')(({ theme }) => ({
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    width: 4,
    height: 4,
    content: "''",
    display: 'block',
    borderRadius: '50%',
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create('transform'),
  },
}));
