import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Collapse,
  type CustomTheme,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  RadioGroup as MUIRadioGroup,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { generateFilterTitle } from '../../utils';
import Label from '../label/Label';

import { FilterListItemStyles } from './styles';

const useStyles = makeStyles(() => ({
  maxHeight: {
    maxHeight: '300px',
    overflowY: 'scroll',
  },
}));

export default function RadioItems({
  open,
  title,
  id,
  radioOptions = [],
  selectedRadioOption,
  handleRadioSelection,
  renderCustomComponent,
  children,
  // prop to default open the radio items
  openByDefault = true,
}: {
  open?: boolean;
  title: string;
  id?: string;
  radioOptions?: { id: string; title: string }[];
  selectedRadioOption?: { title: string };
  handleRadioSelection?: (id: string, value?: string) => void;
  renderCustomComponent?: boolean;
  children?: ReactNode;
  openByDefault?: boolean;
}) {
  const theme = useTheme<CustomTheme>();
  // state to open or close the radio items
  const [show, setShow] = useState(openByDefault);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const popoverRef = useRef(null);
  const pathname = useLocation();

  const handleShow = () => {
    setShow((show) => !show);
  };

  useEffect(() => {
    if (openFilter) {
      handleClose();
    }
  }, [pathname]);

  const handleClose = () => setAnchorEl(null);
  const openFilter = Boolean(anchorEl);

  return (
    <>
      <ListItem
        ref={popoverRef}
        disableGutters
        onClick={handleShow}
        className={open ? 'isActiveRoot' : ''}
        // @ts-ignore
        sx={{
          ...FilterListItemStyles(theme),
          backgroundColor: show ? '#f0f5fd' : '',
        }}
      >
        <ListItemText
          sx={{
            textTransform: 'none',
            fontWeight: 700,
            color: 'text.primary',
          }}
          disableTypography
          primary={generateFilterTitle(title)}
        />
        {/* we will only ever have one radio option selected
            we also set to active if custom radio buttons are passed (for insights)
        */}
        {(selectedRadioOption || renderCustomComponent) && (
          <Label color="primary">1</Label>
        )}
        <Box
          component={Icon}
          icon={show ? arrowIosDownwardFill : arrowIosForwardFill}
          sx={{ width: 16, height: 16, ml: 1 }}
        />
      </ListItem>
      <Collapse in={show}>
        <List
          disablePadding
          sx={{ mt: 0.5, mb: 1 }}
          className={classes.maxHeight}
        >
          {renderCustomComponent ? (
            children
          ) : (
            <MUIRadioGroup
              value={selectedRadioOption?.title}
              onChange={(_, value) => {
                if (!handleRadioSelection) return;
                handleRadioSelection(value, id);
              }}
            >
              {radioOptions.map(({ id, title }) => (
                <FormControlLabel
                  sx={{
                    borderRadius: `${theme.shape.borderRadius}px`,
                    textTransform: 'none',
                    width: 'auto',
                    marginRight: theme.spacing(2),
                    marginLeft: theme.spacing(2),
                    height: 40,
                    '&:hover': {
                      background: '#f0f5fd',
                    },
                  }}
                  key={id}
                  value={title}
                  control={
                    <Radio
                      sx={{
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    />
                  }
                  label={<Typography>{title}</Typography>}
                />
              ))}
            </MUIRadioGroup>
          )}
        </List>
      </Collapse>
    </>
  );
}
