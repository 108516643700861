import {
  Box,
  type CustomTheme,
  Stack,
  type SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { type ReactNode } from 'react';
import { type DropzoneProps, useDropzone } from 'react-dropzone';

import Iconify from '../Iconify';

import Image from './Image';
import RejectionFiles from './RejectionFiles';

const RootStyle = styled('div')(() => {
  const theme = useTheme<CustomTheme>();
  return {
    width: 144,
    height: 144,
    margin: 'auto',
    borderRadius: '50%',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`,
  };
});

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled('div')(() => {
  const theme = useTheme<CustomTheme>();
  return {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': { opacity: 0.72 },
  };
});

export default function UploadAvatar({
  error,
  file,
  helperText,
  sx,
  ...other
}: {
  error?: boolean;
  file: string | { preview: string };
  helperText: ReactNode;
  sx?: SxProps;
} & DropzoneProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Stack>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          <input {...getInputProps()} />

          {file && (
            <Image
              alt="avatar"
              src={typeof file === 'string' ? file : file.preview}
              sx={{ zIndex: 8 }}
              disabledEffect
            />
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 },
              }),
              ...((isDragReject || error) && {
                bgcolor: 'error.light',
              }),
            }}
          >
            <Iconify
              icon="ic:round-add-a-photo"
              sx={{ width: 24, height: 24, mb: 1 }}
            />
            <Typography variant="caption">
              {file ? 'Update photo' : 'Upload photo'}
            </Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>
      <Box>{helperText && helperText}</Box>
      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}
    </Stack>
  );
}
