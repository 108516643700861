import {
  Box,
  Button,
  type ButtonProps,
  type CustomTheme,
  type SxProps,
  useTheme,
} from '@mui/material';
import { forwardRef } from 'react';

type DropDownButtonProps = {
  children: React.ReactNode;
  isSelected?: boolean;
  buttonSx?: SxProps;
  endIcon: React.ReactNode;
} & ButtonProps;

const DropDownButton = forwardRef<HTMLButtonElement, DropDownButtonProps>(
  ({ children, isSelected, buttonSx, ...other }, ref) => {
    const theme = useTheme<CustomTheme>();
    return (
      <Button
        ref={ref}
        variant="ghost"
        color="inherit"
        sx={{
          py: theme.spacing(0.9),
          border: `1px solid ${theme.palette.grey[500_32]}`,
          color: 'text.secondary',
          textTransform: 'none',
          width: { xs: 1, md: 'auto' },
          height: 'fit-content',
          justifyContent: 'flex-start',
          fontWeight: 'fontWeightMedium',
          ...(isSelected && {
            color: 'text.primary',
          }),
          ...buttonSx,
          '&:hover': {
            borderColor: theme.palette.grey[800],
            backgroundColor: 'transparent',
          },
        }}
        {...other}
      >
        {children}
        <Box sx={{ flexGrow: 1 }} />
      </Button>
    );
  },
);

export default DropDownButton;
