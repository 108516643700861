import { Box, IconButton, type SxProps } from '@mui/material';
// @ts-ignore - can't seem to get the types
import { m } from 'framer-motion';
import {
  type ButtonHTMLAttributes,
  forwardRef,
  type PropsWithChildren,
  type ReactNode,
} from 'react';

type MIconButtonProps = {
  children: ReactNode;
  sx?: SxProps;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButtonAnimate = forwardRef<HTMLButtonElement, MIconButtonProps>(
  ({ children, sx, size = 'medium', ...other }, ref) => (
    <AnimateWrap size={size}>
      <IconButton
        size={size === 'tiny' ? 'small' : size}
        ref={ref}
        {...other}
        sx={{
          ...sx,
          ...(size === 'tiny' && { padding: '2px' }),
        }}
      >
        {children}
      </IconButton>
    </AnimateWrap>
  ),
);

export default IconButtonAnimate;

const varTiny = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

function AnimateWrap({ size, children }: PropsWithChildren<{ size: string }>) {
  const isTiny = size === 'tiny';
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={
        (isTiny && varTiny) ||
        (isSmall && varSmall) ||
        (isLarge && varLarge) ||
        varMedium
      }
      sx={{
        display: 'inline-flex',
      }}
    >
      {children}
    </Box>
  );
}
