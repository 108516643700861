import { parseTemplate } from 'url-template';

import {
  type GeneratePathParams,
  type HTTPMethods,
  type QueryFn,
  type QueryParamsObject,
  type RequestConfig,
} from './types';

const encodeUrl = ({
  url,
  variables,
}: {
  url: string;
  variables: Record<string, string | number>;
}) => {
  const urlTemplate = parseTemplate(url);
  return urlTemplate.expand(variables);
};

export const queryBuilder = <
  HTTPMethod extends HTTPMethods,
  Path extends string,
  Query extends QueryParamsObject = never,
  Body extends Record<string, unknown> = never,
>(
  httpMethod: HTTPMethod,
  url: Path,
): QueryFn<Path, Query, Body> => {
  return ({
    path,
    query,
    body,
  }: RequestConfig<GeneratePathParams<Path>, Query, Body>) => {
    const searchParams = new URLSearchParams();
    if (query) {
      for (const [queryParamName, queryParam] of Object.entries(query)) {
        if (
          typeof queryParam === 'number' ||
          typeof queryParam === 'string' ||
          typeof queryParam === 'boolean'
        ) {
          searchParams.append(queryParamName, queryParam.toString());
        } else if (Array.isArray(queryParam)) {
          for (const queryParamValue of queryParam) {
            searchParams.append(queryParamName, queryParamValue.toString());
          }
        } else if (typeof queryParam === 'undefined') {
          continue;
        } else {
          throw new Error(
            `query parameter type ${typeof queryParam} is not supported`,
          );
        }
      }
    }

    return {
      url:
        encodeUrl({ url, variables: path ?? {} }) +
        (searchParams.toString() ? `?${searchParams.toString()}` : ''),
      method: httpMethod,
      body,
    };
  };
};
