import { Box, type BoxProps } from '@mui/material';

export default function Logo({
  src = 'https://customer-images.autone.io/autone-logos/autone-icon-new.svg',
  height = 40,
  ...other
}: {
  src?: string;
  height?: number;
} & BoxProps) {
  return (
    <Box component="img" alt="logo" src={src} height={height} {...other} />
  );
}
