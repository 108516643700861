import {
  type GeneralFiltersStorageKey,
  type NormalizedFilterDimension,
  type NormalizedFilters,
  useGeneralFiltersStorageAtom,
} from '@autone/utils';
import { useMemo } from 'react';

import { type GeneralFiltersProps } from '../Filters.types';

type Props = {
  storageKey?: GeneralFiltersStorageKey;
  allFilters: NormalizedFilters;
  onFilterUpdate?: GeneralFiltersProps['onFilterUpdate'];
  dimension: NormalizedFilterDimension;
  batchId: string;
};

const useSelectedOptionsCount = ({
  batchId,
  allFilters,
  storageKey,
  dimension,
}: Props) => {
  const [generalFiltersStorage] = useGeneralFiltersStorageAtom(
    batchId,
    storageKey,
  );

  const dimensionOptions = useMemo(
    () => generalFiltersStorage?.[dimension],
    [generalFiltersStorage, dimension],
  );

  // TODO: See if we can make this more efficient as complexity of dimensionOptions is O(n^2)
  const currentDimensionFilters = useMemo(
    () =>
      allFilters[dimension]?.map((item) => ({
        ...item,
        active: !!dimensionOptions?.some((filter) => filter.id === item.id),
      })),
    [allFilters, dimension, dimensionOptions],
  );

  const selectedOptionsCount = useMemo(
    () =>
      currentDimensionFilters?.filter((option) => option.active).length || 0,
    [currentDimensionFilters],
  );

  return {
    selectedOptionsCount,
    areAllSelected: selectedOptionsCount === allFilters[dimension]?.length,
  };
};

export default useSelectedOptionsCount;
