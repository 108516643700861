import {
  type GeneralFiltersStorageKey,
  useGeneralFiltersStorageAtom,
} from '@autone/utils';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react';

import {
  type AdvancedFiltersStorage,
  getSessionStorageAtom,
} from '../../utils/storage/session-storage-atom';

import { type AdvancedFiltersProps } from './Filters.types';

const empty = atom<AdvancedFiltersStorage | null>(null);

/**
 * Returns the general filters selection count (stored in localstorage)
 * @param batchId
 * @param generalFiltersStorageKey
 * @returns
 */
export const useGetGeneralFiltersCount = (
  batchId: string,
  generalFiltersStorageKey?: GeneralFiltersStorageKey,
) => {
  const [generalFiltersStorage] = useGeneralFiltersStorageAtom(
    batchId,
    generalFiltersStorageKey,
  );
  if (
    !generalFiltersStorage ||
    Object.keys(generalFiltersStorage).length === 0
  ) {
    return 0;
  }

  const totalGeneralFilters = Object.values(generalFiltersStorage || {}).reduce(
    (acc, options) => {
      return (acc += options.length);
    },
    0,
  );

  return totalGeneralFilters;
};

export const useGetAdvancedFiltersCount = (
  storageKey?: AdvancedFiltersProps['advancedFiltersStorageKey'],
) => {
  const advancedFiltersAtom = useMemo(
    () => (storageKey ? getSessionStorageAtom(storageKey) : empty),
    [storageKey],
  );
  const advancedFiltersStorage = useAtomValue(advancedFiltersAtom);
  const totalAdvancedFilters = advancedFiltersStorage?.conditions.length || 0;

  return totalAdvancedFilters;
};

export const useGetTotalFiltersCount = ({
  batchId,
  generalFiltersStorageKey,
  advancedFiltersStorageKey,
}: {
  batchId: string;
  generalFiltersStorageKey?: GeneralFiltersStorageKey;
  advancedFiltersStorageKey?: AdvancedFiltersProps['advancedFiltersStorageKey'];
}) => {
  const totalGeneralFilters = useGetGeneralFiltersCount(
    batchId,
    generalFiltersStorageKey,
  );
  const totalAdvancedFilters = useGetAdvancedFiltersCount(
    advancedFiltersStorageKey,
  );

  return totalAdvancedFilters + totalGeneralFilters;
};

export const useAdvancedFiltersAtom = (
  storageKey?: AdvancedFiltersProps['advancedFiltersStorageKey'],
) => {
  const advancedFiltersAtom = useMemo(
    () => (storageKey ? getSessionStorageAtom(storageKey) : empty),
    [storageKey],
  );

  const [advancedFiltersStorage, updateAdvancedFiltersStorage] =
    useAtom(advancedFiltersAtom);

  const isEmpty =
    advancedFiltersStorage === null ||
    advancedFiltersStorage.conditions.length === 0;

  return [
    isEmpty ? null : advancedFiltersStorage,
    updateAdvancedFiltersStorage,
  ] as const;
};

export const useAdvancedFiltersValue = (
  storageKey: AdvancedFiltersProps['advancedFiltersStorageKey'],
) => {
  const [advancedFiltersStorage] = useAdvancedFiltersAtom(storageKey);
  return advancedFiltersStorage;
};
