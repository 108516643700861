import { Box, type SxProps } from '@mui/material';
// @ts-ignore - can't seem to get the types
import { motion } from 'framer-motion';
import { type PropsWithChildren } from 'react';

import { varMediumClick, varSmallClick } from './variants';

const ButtonAnimate = ({
  mediumClick = false,
  children,
  sx,
  ...other
}: PropsWithChildren<{ mediumClick?: boolean; sx?: SxProps }>) => {
  return (
    <Box
      component={motion.div}
      whileTap="tap"
      whileHover="hover"
      variants={mediumClick ? varMediumClick : varSmallClick}
      sx={{ display: 'inline-flex', ...sx }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default ButtonAnimate;
