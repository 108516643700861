import { type NormalizedFilters } from '@autone/utils';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';
import { useMemo } from 'react';

export type FiltersStorage = Record<string, Array<string>>;

export interface BatchStorage {
  replenishment: {
    productsTable: {
      generalFilters: NormalizedFilters | null;
    };
    locationsTable: {
      generalFilters: NormalizedFilters | null;
    };
    pivotTable: { generalFilters: NormalizedFilters | null };
  };
  rebalancing: {
    productsTable: {
      generalFilters: NormalizedFilters | null;
    };
    locationsTable: {
      generalFilters: NormalizedFilters | null;
    };
    tripsTable: {
      generalFilters: NormalizedFilters | null;
    };
    pivotTable: { generalFilters: NormalizedFilters | null };
  };
  reorder: {
    productsTable: { generalFilters: NormalizedFilters | null };
  };
}

export const initialSessionStorageData: BatchStorage = {
  replenishment: {
    productsTable: { generalFilters: null },
    locationsTable: { generalFilters: null },
    pivotTable: { generalFilters: null },
  },
  rebalancing: {
    productsTable: { generalFilters: null },
    locationsTable: { generalFilters: null },
    tripsTable: { generalFilters: null },
    pivotTable: { generalFilters: null },
  },
  reorder: {
    productsTable: { generalFilters: null },
  },
};

const triggerEvent = (
  key: string,
  oldValue: string | null,
  newValue: string | null,
) => {
  const event = new StorageEvent('storage', {
    key,
    oldValue,
    newValue,
    storageArea: sessionStorage,
    url: window.location.href,
  });
  window.dispatchEvent(event);
};

export const storage = {
  subscribe: (key: string, callback: (value: BatchStorage) => void) => {
    const storageEventCallback = (e: StorageEvent) => {
      if (e.key === key && e.newValue) {
        callback(JSON.parse(e.newValue));
      }
    };

    window.addEventListener('storage', storageEventCallback);
    return () => {
      window.removeEventListener('storage', storageEventCallback);
    };
  },
  getItem: function (key: string, initialValue: BatchStorage): BatchStorage {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue) {
      try {
        return JSON.parse(storedValue);
      } catch (_) {
        sessionStorage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      }
    }
    return initialValue;
  },
  setItem: function (key: string, newValue: BatchStorage): void {
    const oldValue = sessionStorage.getItem(key);
    const value = JSON.stringify(newValue);
    sessionStorage.setItem(key, value);

    triggerEvent(key, oldValue, value);
  },
  removeItem: function (key: string): void {
    const oldValue = sessionStorage.getItem(key);
    sessionStorage.removeItem(key);

    triggerEvent(key, oldValue, null);
  },
};

export type SessionStorageKeys = PathOf<BatchStorage, false, true>;
export type GeneralFiltersStorageKey = Extract<
  SessionStorageKeys,
  `${string}.${string}.generalFilters`
>;

export const useGeneralFiltersStorageAtom = (
  batchId: string,
  key: GeneralFiltersStorageKey,
) => {
  const baseAtom = useMemo(
    () =>
      atomWithStorage(`batch-${batchId}`, initialSessionStorageData, storage, {
        getOnInit: true,
      }),
    [batchId],
  );
  const focusedAtom = useMemo(
    () => focusAtom(baseAtom, (optic) => optic.path(key)),
    [baseAtom, key],
  );
  return useAtom(focusedAtom);
};
