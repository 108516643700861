import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import infoFill from '@iconify/icons-eva/info-fill';
import { type IconifyIcon } from '@iconify/types';
import { Box, GlobalStyles } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { type SnackbarKey, SnackbarProvider } from 'notistack';
import { type PropsWithChildren, useRef } from 'react';

import { IconButtonAnimate } from '../animate';
import Iconify from '../Iconify';

function SnackbarStyles() {
  const theme = useTheme();

  const createStyle = {
    fontSize: theme.typography.body2.fontSize,
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: `${theme.palette.background.paper} !important`,
  };

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .notistack-MuiContent-success': {
            ...createStyle,
          },
          '& .notistack-MuiContent-info': {
            ...createStyle,
          },
          '& .notistack-MuiContent-warning': {
            ...createStyle,
          },
          '& .notistack-MuiContent-error': {
            ...createStyle,
          },
        },
      }}
    />
  );
}

export default function NotistackProvider({ children }: PropsWithChildren) {
  const notistackRef = useRef<null | SnackbarProvider>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />
      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        //   autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon={infoFill} color="info" />,
          success: <SnackbarIcon icon={checkmarkCircle2Fill} color="success" />,
          warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
          error: <SnackbarIcon icon={alertCircleFill} color="error" />,
        }}
        // With close as default
        action={(key) => (
          <IconButtonAnimate
            size="small"
            onClick={onClose(key)}
            sx={{ p: 0.5 }}
          >
            <Iconify icon={closeFill} />
          </IconButtonAnimate>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}

function SnackbarIcon({
  icon,
  color,
}: {
  icon: IconifyIcon;
  color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
}) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Box>
  );
}
