import { IconButton, type SxProps } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react';

import ButtonAnimate from '../animate/ButtonAnimate';

type MIconButtonProps = {
  children: ReactNode;
  sx?: SxProps;
  color?:
    | 'default'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const MIconButton = forwardRef<HTMLButtonElement, MIconButtonProps>(
  ({ color = 'default', children, sx, ...other }, ref) => {
    const theme = useTheme();

    if (
      color === 'default' ||
      color === 'inherit' ||
      color === 'primary' ||
      color === 'secondary'
    ) {
      return (
        <ButtonAnimate>
          <IconButton ref={ref} color={color} sx={sx} {...other} size="large">
            {children}
          </IconButton>
        </ButtonAnimate>
      );
    }

    return (
      <ButtonAnimate>
        <IconButton
          ref={ref}
          sx={{
            color: theme.palette[color].main,
            '&:hover': {
              bgcolor: alpha(
                theme.palette[color].main,
                theme.palette.action.hoverOpacity,
              ),
            },
            ...sx,
          }}
          {...other}
          size="large"
        >
          {children}
        </IconButton>
      </ButtonAnimate>
    );
  },
);

export default MIconButton;
