import { type CommonTranslationFunction } from '@autone/translations';
import { formatDate as formatDateFn } from '@autone/utils';
import { isValid } from 'date-fns';

export const isFiniteNumber = (num?: number | null) => {
  return num !== undefined && num !== null && isFinite(num);
};

export const formatDate = (
  date: Date | undefined | null,
  locale: string,
  common: CommonTranslationFunction,
) => {
  return isValid(new Date(date ?? ''))
    ? formatDateFn(new Date(date ?? ''), 'dd/MM/yy', locale)
    : common('na');
};
